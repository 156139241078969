import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Spectrum } from 'src/app/timeseries/model/spectrum.model';
import {
  loadInitialTimeseriesSpectrum,
  loadNextTimeseriesSpectrum,
  loadPreviousTimeseriesSpectrum,
  loadTimeseriesSpectrumFail,
  loadTimeseriesSpectrumSuccess,
  removeTimeseriesSpectrum,
  resetSpectra
} from '../actions/timeseries.actions';

export const spectrumFeatureKey = 'spectrum';

export interface SpectrumState extends EntityState<Spectrum> {
  loading: boolean;
  error: string;
}

export const adapter: EntityAdapter<Spectrum> = createEntityAdapter<Spectrum>(
  {
    selectId: (spectra) => spectra.chartId + spectra.id + spectra.property.path,
  }
);

export const initialState = adapter.getInitialState({
  loading: false,
  error: ''
});

export const reducer = createReducer(
  initialState,
  on(loadNextTimeseriesSpectrum, (state, action) => {
    return {
      ...state,
      loading: true,
      error: ''
    };
  }),
  on(loadPreviousTimeseriesSpectrum, (state, action) => {
    return {
      ...state,
      loading: true,
      error: ''
    };
  }),
  on(loadInitialTimeseriesSpectrum, (state, action) => {
    return {
      ...state,
      loading: true,
      error: ''
    };
  }),
  on(loadTimeseriesSpectrumSuccess, (state, action) => {
    return {
      ...adapter.upsertOne(new Spectrum(
        action.chartId,
        action.uuid,
        action.property,
        action.timestamp,
        action.currentPage,
        action.pageCount,
        action.payload,
        action.xMin,
        action.xMax,
        action.from,
        action.to,
        action.color
      ), state),
      loading: false,
      error: '',
    };
  }),
  on(loadTimeseriesSpectrumFail, (state, action) => {
    return {
      ...state,
      loading: false,
      error: 'Spectra failed: ' + action.payload
    };
  }),
  on(removeTimeseriesSpectrum, (state, action) => {
    return {
      ...adapter.removeOne(
        action.chartId + action.uuid + action.property, state),
      loading: false,
      error: '',
    };
  }),
  on(resetSpectra, (state, action) => {
    return {
      ...adapter.removeAll(state),
      loading: false,
      error: ''
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export const isLoading = (state: SpectrumState): boolean => state.loading;
export const getError = (state: SpectrumState): string => state.error;
