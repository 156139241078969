import { AssetProperty } from 'src/app/asset-management/models/asset-property';

export class Spectrum {
  chartId: string;
  id: string;
  property: AssetProperty;
  data: any;
  timestamp: string;
  currentPage: number;
  pageCount: number;
  from: string;
  to: string;
  color: string;
  yMin?: number;
  yMax?: number;
  xMin?: number;
  xMax?: number;

  constructor(
    chartId: string,
    id,
    property,
    timestamp: string,
    currentPage: number,
    pageCount: number,
    data?: any,
    xMin?: number,
    xMax?: number,
    from?: string,
    to?: string,
    color?: string,
    yMin?: number,
    yMax?: number
  ) {
    this.chartId = chartId;
    this.timestamp = timestamp;
    this.currentPage = currentPage;
    this.pageCount = pageCount;
    this.xMin = xMin;
    this.xMax = xMax;
    this.id = id;
    this.property = property;
    this.data = data;
    this.from = from;
    this.to = to;
    this.color = color;
    this.yMin = yMin;
    this.yMax = yMax;
  }
}
